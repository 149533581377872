.button:hover {
    background-color: white;
    color: #26384c;
    /* filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%); */
    .filter {
        filter: invert(17%) sepia(10%) saturate(2519%) hue-rotate(172deg) brightness(97%) contrast(87%);
    }
}

.button {
    padding: 5px;
}
.run-button:hover {
    background-color: #e6e7ec;
    color: #0f1b33;
}
.run-button {
    cursor: pointer;
    border-radius: 12px;
}

.button-red:hover {
    background-color: #901617;
    color: #0f1b33;
}