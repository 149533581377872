.button:hover {
    background-color: #26384c;
    color: #e6e7ec;
    /* filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%); */
    .filter {
        filter: invert(17%) sepia(10%) saturate(2519%) hue-rotate(172deg) brightness(97%) contrast(87%);
    }
}

.button {
    padding: 10px;
    border-radius: 12px;
}

.toggle-button:hover {
    background-color: #485b79 !important; 
    color: white;
}

.run-button:hover {
    background-color: #b7bac5 !important;   
}
.run-button {
    padding: 10px;
    border-radius: 12px;
}