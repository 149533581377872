.main-wrapper * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} 

.body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #485b79;
}

.signin-field {
    border-radius: 20px;
    padding: 12px;
    margin-top: auto;
    margin-bottom: 19px;
    margin-top: 6px;
}

.wrapper {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #485b79;
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: calc(100vh - 100px); */
    /* padding-bottom: 200px; */
    height: 100vh;
    margin-top: 0;
    flex-direction: column;
    gap: 10px;
    background: #485b79;
}

.box {
    color: #FFF;
    background: #111b34;
    border-radius: 10px;
    padding: 30px 40px;
    max-width: 420px;
    max-height: 425px;
    flex: 2;
}

.box h1 {
    font-size: 36px;
    text-align: center;
}

.box .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
    /* max-width: '200px';
    margin-left: "auto";
    margin-right: "auto";
    margin-bottom: "20px"; */
}

.input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 20px;
}

.input-box .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
}

.input-box input::placeholder {
    color: #fff;
}

.box button {
    width: 100%;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 700;
}

.box .register-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
}

.register-link p span {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

.register-link p span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.input-wrapper {
    margin-top: 30px;
    margin-bottom: auto;
    margin-right: auto;
    margin-left: auto;
    padding: 50px;
    border-radius: 10px;
    width: fit-content;
    margin-right: auto;
    background-color: white
}

.logobox {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto
}

.logo-wrapper {
    align-content: center;
    flex: 1;
}

.logo-signin {
    margin-top: 6rem;
    border-radius: 100px;
    align-content: center;
}

